import { useField } from "formik";
import { MyCheckBoxInputProps } from "../../Interfaces";
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Paper, Typography } from "@mui/material";
import React from "react";
import ScrollContainer from "./ScrollContainer";

export const TermsContainer = (props: MyCheckBoxInputProps) => {
    const { label, description, beforeContent, afterContent, config, ...inputProps } = props;
    const [field, meta] = useField(inputProps);

    return (
        <>
            <Paper>
                <Box sx={{ padding: '1rem' }}>
                    <Typography m={0} mb={1} fontWeight={'bold'} dangerouslySetInnerHTML={{ __html: label }}></Typography>
                   
                    {(config && config.termslink === '' && config.termstext !== '') && (<ScrollContainer noScroll={true}>
                        {(config && config.termstext !== '') ? <Box dangerouslySetInnerHTML={{ __html: config.termstext }}></Box> : null}
                    </ScrollContainer>)}
                    

                    <FormControl error={!!(meta.touched && meta.error)}>
                        {description && <Typography variant="body1">{description}</Typography>}
                        <FormControlLabel control={<Checkbox {...field} />} label={<FormLabel sx={{ color: 'var(--color-primary)' }}>{config.checkboxlabel}</FormLabel>} />
                        {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
                    </FormControl>

                    {(config && config.termslink !== '') && <Box mt={1}><a target="_blank" href={config.termslink}>{label} lesen</a> </Box>}
                </Box>
            </Paper>
        </>
    )
}
